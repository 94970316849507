<template>
    <div class="p-16 shadow" style="background-color:#F8F8F8">
        <p class="text-3xl mb-10 leading-normal">Hier können Sie das Passwort für Ihr Konto ändern</p>
        <form @submit.prevent="submitForm">
            <div class="form-control max-w-3xl">
                <label>Aktuelles Passwort:</label>
                <input type="password" v-model="current" @focus="removeReadonly('current')" ref="currentInput" autocomplete="off">
                <i class="fas fa-eye" @click="toggleCurrent"></i>
            </div>
            <div class="form-control max-w-3xl">
                <label>Passwort:</label>
                <input type="password" v-model="password" @focus="removeReadonly('password')" ref="passwordInput" autocomplete="off">
                <i class="fas fa-eye" @click="togglePassword"></i>
            </div>
            <button type="submit" class="primary-btn">Aktualisieren</button>
        </form>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
    export default {
        data() {
            return {
                password: '',
                current: ''
            }
        },
        mounted() {
            this.$refs.passwordInput.setAttribute("readonly", "true");
            this.$refs.currentInput.setAttribute("readonly", "true");
        },
        methods: {
            ...mapActions(['changePw']),
            togglePassword() {
                if(this.$refs.passwordInput.type == 'password') {
                    this.$refs.passwordInput.type = 'text';
                }
                else {
                    this.$refs.passwordInput.type = 'password';
                }
            },
            toggleCurrent() {
                if(this.$refs.currentInput.type == 'password') {
                    this.$refs.currentInput.type = 'text';
                }
                else {
                    this.$refs.currentInput.type = 'password';
                }
            },
            removeReadonly(type) {
                if(type == 'password') {
                    this.$refs.passwordInput.removeAttribute("readonly");
                    this.$refs.passwordInput.focus();  
                } else {
                    this.$refs.currentInput.removeAttribute("readonly");
                    this.$refs.currentInput.focus();
                }
            },
            submitForm() {
                if(this.password.length && this.current.length) {
                    this.changePw({
                        current: this.current,
                        password: this.password
                    });
                }
            }
        }
    }
</script>

